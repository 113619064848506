function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

const age = document.getElementById('age');
const poops = document.getElementById('poops');

let numberOfPoops = 4;
let unit = 'days';

const dob = new Date(2020, 02, 29, 10, 30);
const setAge = () => {
    const seconds = Math.floor((Date.now() - dob.getTime()) / 1000);

    const days = seconds / 60 / 60 / 24;

    if (unit == 'seconds') {
        age.innerText = `${numberWithCommas(seconds)} seconds`;
    } else {
        age.innerText = `${days.toFixed(2)} days`;
    }

    poops.innerText = Math.floor(days * 9);
};
setAge();
setInterval(setAge, 1000);

age.onclick = () => {
    unit = unit == 'seconds' ? 'days' : 'seconds';
    setAge();
};
